@use '@angular/material' as mat;

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

h2 {
  color: #000000;
}

//**************** LIGHT-THEME **************** //
$acura-primary-palette: (
  50: #1a0d00,
  100: #422200,
  200: #663400,
  300: #994e00,
  400: #cc6800,
  500: #ff8607,
  600: #ff9b33,
  700: #ffbc75,
  800: #ffdab2,
  900: #FFF8F5,
  contrast: (50: black,
    100: black,
    200: black,
    300: white,
    400: white,
    500: white,
    600: white,
  ),
);

$acura-accent-palette: (
  50: #030B17,
  100: #05162E,
  200: #0A2C5C,
  300: #0D3C7D,
  400: #1252AB,
  500: #1768D9,
  600: #3983EA,
  700: #67A0EF,
  800: #95BDF4,
  900: #C3D9F9,
  contrast: (50: black,
    100: black,
    200: black,
    300: white,
    400: white,
    500: white,
    600: white,
  ),
);

$color-palette-primary: mat.define-palette($acura-primary-palette);
$color-palette-accent: mat.define-palette($acura-accent-palette);
$color-palette-warn: mat.define-palette(mat.$red-palette);

$typography-config: mat.define-typography-config($font-family: "Roboto, sans-serif",
  );

$light-theme: mat.define-light-theme((color: (primary: $color-palette-primary,
        accent: $color-palette-accent,
        warn: $color-palette-warn ),
      typography: $typography-config ));
//**************** DARK-THEME **************** //
//Just an example of how to use and toggle themes, also look app.components.ts
$color-dark-palette-primary: mat.define-palette(mat.$red-palette);
$color-dark-palette-accent: mat.define-palette(mat.$pink-palette);
$color-dark-palette-warn: mat.define-palette(mat.$blue-gray-palette);

$dark-typography: mat.define-typography-config($font-family: "Times New Roman, sans-serif",
  );

$dark-theme: mat.define-dark-theme((color: (primary: $color-dark-palette-primary,
        accent: $color-dark-palette-accent,
        warn: $color-dark-palette-warn ),
      typography: $dark-typography ));

body.light-theme {
  @include mat.all-component-themes($light-theme);
}

body.dark-theme {
  @include mat.all-component-themes($dark-theme);
}

// Exemplo de como adicionar o tema a apenas um componente,e neste caso, ao checkbox:



// /* You can add global styles to this file, and also import other style files */

// $my-custom-typography-config: mat.define-typography-config(
//   $headline-1:
//     mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
//   $headline-2:
//     mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
//   $headline-3:
//     mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
//   $headline-4: mat.define-typography-level(34px, 40px, 400),
//   $headline-5: mat.define-typography-level(24px, 32px, 400),
// );

// $acura-palette: (
//   50: #1a0d00,
//   100: #422200,
//   200: #663400,
//   300: #994e00,
//   400: #cc6800,
//   500: #ff8607,
//   600: #ff9b33,
//   700: #ffbc75,
//   800: #ffdab2,
//   900: #ffe9d1,
//   contrast: (
//     50: rgba(black, 0.87),
//     100: rgba(black, 0.87),
//     200: rgba(black, 0.87),
//     300: white,
//     400: white,
//     500: white,
//     600: white,
//     700: white,
//     800: white,
//     900: white,
//   ),
// );

// $m3-acura-palette: (
//   0: #000000,
//   10: #422200,
//   20: #663400,
//   25: #804100,
//   30: #994e00,
//   35: #B25B00,
//   40: #cc6800,
//   50: #ff8607,
//   60: #ff9b33,
//   70: #ffbc75,
//   80: #ffdab2,
//   90: #ffe9d1,
//   95: #f7edff,
//   98: #fef7ff,
//   99: #fffbff,
//   100: #ffffff,
//   secondary: (
//     0: #000000,
//     10: #1f182a,
//     20: #352d40,
//     25: #40384c,
//     30: #4b4357,
//     35: #574f63,
//     40: #645b70,
//     50: #7d7389,
//     60: #978ca4,
//     70: #b2a7bf,
//     80: #cec2db,
//     90: #eadef7,
//     95: #f7edff,
//     98: #fef7ff,
//     99: #fffbff,
//     100: #ffffff,
//   ),
//   neutral: (
//     0: #000000,
//     10: #1d1b1e,
//     20: #323033,
//     25: #3d3a3e,
//     30: #49464a,
//     35: #545155,
//     40: #605d61,
//     50: #7a767a,
//     60: #948f94,
//     70: #aeaaae,
//     80: #cac5ca,
//     90: #e6e1e6,
//     95: #f5eff4,
//     98: #fef8fc,
//     99: #fffbff,
//     100: #ffffff,
//   ),
//   neutral-variant: (
//     0: #000000,
//     10: #1d1a22,
//     20: #332f37,
//     25: #3e3a42,
//     30: #49454e,
//     35: #55515a,
//     40: #615c66,
//     50: #7b757f,
//     60: #958e99,
//     70: #b0a9b3,
//     80: #cbc4cf,
//     90: #e8e0eb,
//     95: #f6eef9,
//     98: #fef7ff,
//     99: #fffbff,
//     100: #ffffff,
//   ),
//   error: (
//     0: #000000,
//     10: #410002,
//     20: #690005,
//     25: #7e0007,
//     30: #93000a,
//     35: #a80710,
//     40: #ba1a1a,
//     50: #de3730,
//     60: #ff5449,
//     70: #ff897d,
//     80: #ffb4ab,
//     90: #ffdad6,
//     95: #ffedea,
//     98: #fff8f7,
//     99: #fffbff,
//     100: #ffffff,
//   ),
// );

// $m3-dark-theme: matx.define-theme(
//   (
//     color: (
//       theme-type: dark,
//       primary: matx.$m3-blue-palette,
//       tertiary: matx.$m3-blue-palette,
//     ),
//   )
// );

// $m3-light-theme: matx.define-theme(
//   (
//     color: (
//       primary: $m3-acura-palette,
//       // tertiary: matx.$m3-blue-palette,
//     ),
//   )
// );

// html,
// body {
//   // @include matx.color-variants-back-compat($m3-acura-palette);
// }

// body {
// }

// // .dark-theme {
// //   @include mat.all-component-themes($m3-dark-theme);
// // }

// // .light-theme {
// //   @include mat.all-component-themes($m3-light-theme);
// // }
